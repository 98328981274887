import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import sideBarReducer from './sideBarReducer'
import customBreadcrumbReducer from './customBreadcrumbReducer'
import errorReducer from './errorReducer'
import toastReducer from './toastReducer'
import userReducer from './userReducer'
import homeWorkReducer from './homework/homeWorkReducer'
import handbookReducer from './handbook/handbookReducer'
import settingReducer from './setting/settingReducer'
import scheduleReducer from './schedule/scheduleReducer'
import onlineRoomReducer from './onlineRoom/onlineRoomReducer'
import notificationReducer from './notification/notificationReducer'
import dashboardReducer from './dashboard/dashboardReducer'
import backendErrorsReducer from './backendErrors/backendErrorsReducer'
import menuReducer from './menu/menuReducer'
import pageReducer from './page/pageReducer'
import progressReducer from './progress/progressReducer'
import emailReducer from './email/emailReducer'
import usersProgressReducer from './users/usersReducer'
import homeTaskReducer from './homeTask/homeTaskReducer'
import loadingReducer from './loading/loadingReducer'
import profilesReducer from './profiles/profilesReducer'
import announcementReducer from './announcement/announcementReducer'
import referenceReducer from './reference/referenceReducer'
import appealReducer from './appeal/appealReducer'
import stipendReducer from './stipend/stipendReducer'
import headmansReducer from './headmans/headmansReducer'
import electronicJournalReducer from './electronicJournal/electronicJournalReducer'
import absenceReasonsReducer from './absenceReasons/absenceReasonsReducer'
import currentProgressReducer from './currentProgress/currentProgressReducer'
import paySlipsReducer from './paySlips/paySlipsReducer'
import recipientsReasonsFailureReducer from './recipientsReasonsFailure/recipientsReasonsFailureReducer'
import infoReducer from './info/infoReducer'
import statementsReducer from './statements/statementsReducer'

const rootReducer = combineReducers({
  sidebar: sideBarReducer,
  breadcrumbs: customBreadcrumbReducer,
  error: errorReducer,
  toast: toastReducer,
  homework: homeWorkReducer,
  homeTask: homeTaskReducer,
  handbook: handbookReducer,
  setting: settingReducer,
  user: userReducer,
  schedule: scheduleReducer,
  onlineRoom: onlineRoomReducer,
  notification: notificationReducer,
  dashboard: dashboardReducer,
  backendErrors: backendErrorsReducer,
  page: pageReducer,
  menu: menuReducer,
  progress: progressReducer,
  email: emailReducer,
  usersProgressReducer: usersProgressReducer,
  loading: loadingReducer,
  profiles: profilesReducer,
  announcement: announcementReducer,
  reference: referenceReducer,
  appeal: appealReducer,
  stipend: stipendReducer,
  headmans: headmansReducer,
  electronicJournal: electronicJournalReducer,
  absenceReasons: absenceReasonsReducer,
  currentProgress: currentProgressReducer,
  paySlips: paySlipsReducer,
  recipientsReasonsFailure: recipientsReasonsFailureReducer,
  info: infoReducer,
  statements: statementsReducer,
})

const devToolsExtension =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

const isDebug = process.env.REACT_APP_DEBUG

const enhancers = [applyMiddleware(thunk), isDebug ? devToolsExtension : undefined].filter(
  (el) => el !== undefined,
)

const store = createStore(rootReducer, compose(...enhancers))

export default store
